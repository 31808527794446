body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.strokeText {
  position: relative;
  z-index: 1;
}

.strokeText::before {
  content: attr(data-storke);
  position: absolute;
  z-index: -1;
  -webkit-text-stroke: 2px #000;
  text-stroke: 2px #000;
}

.strokeText.thick::before {
  content: attr(data-storke);
  position: absolute;
  z-index: -1;
  -webkit-text-stroke: 5px #000;
  text-stroke: 5px #000;
}